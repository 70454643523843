import React, { useState, useCallback } from 'react';
import { Divider, List, DescriptionOutlinedIcon, PlaceOutlinedIcon, AirOutlinedIcon, GridViewIcon, RemoveRedEyeOutlinedIcon } from 'src/shared';
import './navigation.css';
import SideNavMenuItem from './side-nav-menu-item';
import Logo from "src/static/img/logos/icon-logo.png";
import LogoFull from "src/static/img/logos/wording-logo.png";
import { _ } from 'src/utils';
import { Drawer } from './side-nav-drawer';
import { useTranslation } from 'src/contexts/TranslationContext';

const Navigation = ({ sideNavOpen, handleSideNavChange }) => {
  // State
  const [open, setOpen] = useState(sideNavOpen);
  const [currentMenuItemExpanded, setCurrentMenuItemExpanded] = useState(null);

  const { gettext } = useTranslation()

  // Handlers
  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
    handleSideNavChange(true);
  }, [handleSideNavChange]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
    handleSideNavChange(false);
  }, [handleSideNavChange]);

  const changeCurrentMenuItem = useCallback((title) => {
    setCurrentMenuItemExpanded(title);
  }, []);

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
    >
      <Divider />
      <List>
        {/* Logo */}
        <div
          className="logo-container"
          onClick={() => (window.location.href = "/")}
        >
          <img className="logo-image" src={Logo} alt="Logo" />
          {open ? <img className="logo-image-full" src={LogoFull} alt="Full Logo" /> : null}
        </div>

        {/* SideNavMenuItems */}
        <SideNavMenuItem
          title={gettext("Dashboard")}
          icon={<GridViewIcon />}
          isDropdown={true}
          subTitles={[gettext("Global"), gettext("EU"), gettext("ESP")]}
          links={["/dashboard/global", "/dashboard/eu", "/dashboard/esp"]}
          open={open}
        />

        <SideNavMenuItem
          title={gettext("At a Glance")}
          icon={<RemoveRedEyeOutlinedIcon />}
          isDropdown={true}
          subTitles={[gettext("Global"), gettext("EU"), gettext("ESP")]}
          links={["/global/glance", "/eu/glance", "/esp/glance"]}
          open={open}
        />

        <SideNavMenuItem
          title={gettext("EU ETS Allowances")}
          icon={<AirOutlinedIcon />}
          isDropdown={true}
          subTitles={[
            gettext("Prices"),
            gettext("Volume"),
            gettext("National Breakdown"),
            gettext("Market and Auction"),
          ]}
          links={[
            "/emissions/prices",
            "/emissions/volume",
            "/emissions/breakdown",
            "/emissions/summary",
          ]}
          open={open}
        />

        <SideNavMenuItem
          title={gettext("Maps")}
          icon={<PlaceOutlinedIcon />}
          isDropdown={false}
          links={["/maps"]}
          open={open}
        />

        {/* Uncomment if needed */}
        {/* <SideNavMenuItem
          title={_("Summaries")}
          icon={<DescriptionOutlinedIcon />}
          isDropdown={false}
          links={["/summaries"]}
          open={open}
        /> */}
      </List>
    </Drawer>
  );
};

export default Navigation;