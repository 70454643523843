import React from 'react';
import { useTranslation } from 'src/contexts/TranslationContext';
import { Typography, customTheme } from 'src/shared';
import { _ } from 'src/utils'

export function InvalidFormState (props) {
    return (
        <div style={{
            borderRadius: '4px', 
            padding: '7px', 
            textAlign: 'left', 
            backgroundColor: customTheme.palette.error.main,
            fontSize: '10px',
            paddingLeft: '10px'}}>
                <Typography variant="body2" sx={{fontSize: '11px'}}> 
                    {`Oops! ${props.message}`}
                </Typography>
        </div>
    )
}

export function ApiError(){
    const { gettext } = useTranslation()
    const apiErrorMessage = gettext("Our auth service is down. Try again later");
    return(<InvalidFormState message={apiErrorMessage}/>)
}

export function ValidFormState (props) {
    return (
        <div style={{
            borderRadius: '4px', 
            padding: '7px', 
            textAlign: 'left', 
            backgroundColor: customTheme.palette.secondary.main,
            fontSize: '10px',
            paddingLeft: '10px'}}>
                <Typography variant="body2" sx={{fontSize: '11px'}}> 
                    {`${props.message}`}
                </Typography>
        </div>
    )
}