import { MultiBarChart, MultiLineChart, MultiPieChart, MultiTable, MultiRadarChart, LineGraph, MultiColumnChart, BarGraph } from "src/visualisations";
import { Loader, ToggleButtonGroupFilter, WarningBox } from 'src/shared'
import { APIFailurePlaceholderImage, _ } from 'src/utils'
import { useTranslation } from "src/contexts/TranslationContext";

export function Chart(props){
    return (
        <InternalChart 
            {...props} 
            sub_category={props.selectedChart?.hasOwnProperty('sub_category') 
                            ? <SubCategory {...props}/> 
                            : <></>}/>
    )
}

export function InternalChart(props){

    const {gettext} = useTranslation()

    let data = props.chartData?.data

    if(props.loadingSelectedChart) {
        return <Loader/>
    }
    else if(props.chartData == null || props.chartData == undefined || props.chartData.status == 400) {
        return (
            <div>
                <WarningBox error={true} width={'32%'} text={gettext("No valid data: Unfortunately there is no data for the parameters you have selected, please try a different combination")}/>
                <div style={{marginTop: '20px'}}>
                    <img style={{maxWidth: '100%', width: '100%'}} src={APIFailurePlaceholderImage(props.selectedChart.type)}/>
                </div>
            </div>
        )
    }
    switch(props.selectedChart.type){
        case 'single_line': return <LineGraph data={data} sub_category={props.sub_category}/>
        case 'single_bar': return <BarGraph data={data} sub_category={props.sub_category}/>
        case 'line': return <MultiLineChart data={data} sub_category={props.sub_category}/>
        case 'pie': return <MultiPieChart data={data} sub_category={props.sub_category}/>
        case 'radar': return <MultiRadarChart data={data} sub_category={props.sub_category}/>
        case 'bar': return <MultiBarChart data={data} sub_category={props.sub_category}/>
        case 'column': return <MultiColumnChart data={data} sub_category={props.sub_category}/>
        case 'table': return <MultiTable data={data}/>
        default: return <MultiLineChart data={data} sub_category={props.sub_category}/>
    }
}

export function SubCategory(props){

    const {gettext} = useTranslation()

    var ButtonTypes = [
        { value: "co2-eq", viewValue: gettext("Total CO2 Emissions") },
        { value: "co2-eq-per-capita", viewValue: gettext("CO2 Emissions Per Capita") },
        { value: "dir-eq", viewValue: gettext("Directive Emissions")},
        { value: "raw", viewValue: gettext("Raw")},
        { value: "percentage", viewValue: gettext("Percentage")},
        //NEED TO ADD ALL
    ]

    let opts = props.selectedChart.sub_category

    let values = []
    ButtonTypes.forEach(element => {
        if (opts.indexOf(element.value) !== -1) values.push(element)
    });

    return (
        <ToggleButtonGroupFilter 
            values={values} 
            selected={props.subcat} 
            onCategoryChange={props.setSubcat}
            disabled={!(props.selectedChart.purchased || props.selectedChart.price == 0)}
        />
    )
}
