import React, { useState, useEffect } from 'react'
import { _, VisualisationService } from 'src/utils'
import '../glance.css'
import { TabSelector, HelpTooltip, GraphOrLoading, Typography } from 'src/shared'
import EUGlanceGraph from './eu-glance-graph'
import { useTranslation } from 'src/contexts/TranslationContext'

function EUGlancePage() {
    const [page, set_page] = useState('co2')
    const [map1, set_map1] = useState(null)
    const [map2, set_map2] = useState(null)
    const [typo, set_typo] = useState(null)
    const [co2, set_co2] = useState(null)
    const {gettext} = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            await VisualisationService.getChartData('eu/glance', 'map1', set_map1);
            await VisualisationService.getChartData('eu/glance', 'map2', set_map2);
            await VisualisationService.getChartData('eu/glance', 'typo', set_typo);
            await VisualisationService.getChartData('eu/glance', 'co2', set_co2);
        };
        
        fetchData();
    }, []);

    const getData = () => {
        switch (page) {
            case 'map1':
                return map1;
            case 'map2':
                return map2; 
            case 'typo':
                return typo; 
            case 'co2':
                return co2; 
            default:
                return null;
        }
    }

    const handleCurrentViewChange = view => {
        set_page(view);
    };

    return (
        <div>

            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="h1" mb={1}> 
                    {gettext("At a Glance - European Union")} 
                </Typography>
                <HelpTooltip />
            </div>

            <TabSelector 
                titles={[gettext("CO2 Summary"), gettext("Choropleth"), gettext("Emissions Typology")]} 
                links={["co2", "map1", "typo"]} 
                handleChange={handleCurrentViewChange}/>

            <GraphOrLoading 
                loading={getData()===null} 
                graph={
                    <EUGlanceGraph 
                        data={getData()} 
                        page={page}/>}/>
        </div>
    );
}

export default EUGlancePage
