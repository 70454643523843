import i18njs from 'roddeh-i18n';
import {Storage} from "src/utils"

/**
 * Initialize i18njs with the provided language data.
 * @param {string} language_code ISO 639-1 language code.
 * @returns {function} gettext function for translations.
 */
const initializeI18n = (language_code) => {
  try {
    const language_data = require(`../locale/${language_code}.json`);
    return i18njs.create(language_data);
  } catch (error) {
    console.error(`[i18n] Language file for '${language_code}' not found. Falling back to 'es'.`);
    const fallback_data = require(`../locale/es.json`);
    return i18njs.create(fallback_data);
  }
};

/**
 * Get the language code (ISO 639-1) from a given country code.
 * @param {string} country_code Country code (e.g., 'GB', 'ES').
 * @returns {string} Language code (e.g., 'en', 'es').
 */
const getLanguageCodeFromCountryCode = (country_code) => {
  switch (country_code) {
    case 'GB':
      return 'en';
    case 'ES':
      return 'es';
    default:
      console.warn(`[i18n] No language for unsupported country '${country_code}', defaulting to 'es'.`);
      return 'es';
  }
};

/**
 * Get the country code from a given language code.
 * @param {string} language_code Language code (e.g., 'en', 'es').
 * @returns {string} Country code (e.g., 'GB', 'ES').
 */
const getCountryCodeFromLanguageCode = (language_code) => {
  switch (language_code) {
    case 'en':
      return 'GB';
    case 'es':
      return 'ES';
    default:
      console.warn(`[i18n] No country for unsupported language '${language_code}', defaulting to 'ES'.`);
      return 'ES';
  }
};

/**
 * Detect the user's browser language.
 * @returns {string} Language code (e.g., 'en', 'es').
 */
const detectUserLanguage = () => {
  try {
    const lang = Storage.get("language_code")
    if (lang == '' || lang == null || !lang) throw new Error()
    return lang
  } catch {
    console.warn(`[i18n] Couldn't load language from local storage, attempting to detect language`);
    const language = navigator.language || navigator.userLanguage;
    if (language.startsWith('en')) return 'en';
    if (language.startsWith('es')) return 'es';
  
    console.warn(`[i18n] Browser language '${language}' is unsupported, defaulting to 'es'.`);
    return 'es';
  }
  
};

export { initializeI18n, getLanguageCodeFromCountryCode, getCountryCodeFromLanguageCode, detectUserLanguage };