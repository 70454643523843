import React, { useEffect, useState } from 'react';
import { _, API, default_end_year } from 'src/utils'
import { MapsHeader } from './maps-header';
import { Choropleth } from 'src/visualisations'
import FilterStruct, { Card, CardContent, Box, Typography, ToggleButtonGroupFilter } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

export function MapsPage () {
    const {gettext} = useTranslation()
    
    let MapButtonTypes = [
        { value: "co2-eq", viewValue: gettext("Total Emissions") },
        { value: "co2-eq-per-capita", viewValue: gettext("Emissions Per Capita") },
        { value: "dir-eq", viewValue: gettext("Directive Emissions")}
    ]

    const [currentMapTitle, setCurrentMapTitle] = useState(FilterStruct.MapTypes["GeographicGroups"]);
    const [mapTitle, setMapTitle] = useState(FilterStruct.MapTypes["GeographicGroups"]);
    const [map, setMap] = useState("GeographicGroups")
    const [mapSubCategory, setMapSubCategory] = useState(MapButtonTypes[0].value);
    const [year, setYear] = useState(default_end_year(FilterStruct.Years));
    const [data, setData] = useState(null)
    const [countryData, setCountryData] = useState(null)
    const [sector, setSector] = useState(FilterStruct.ESPSectors['Aluminum'])
    const [region, setRegion] = useState(FilterStruct.ESPRegions['Andalusia'])

    useEffect(() => {
        setCurrentMapTitle(mapTitle)
        getData();
    }, [mapSubCategory, mapTitle, year, sector, region])

    const changeTitle = mapType => {
        setMapTitle(FilterStruct.MapTypes[mapType]);
    };

    const onSubmit = () => {
        getData();
        setCurrentMapTitle(mapTitle)
    }

    const getData = () => {
        setData(null)
        let tempMap = Object.keys(FilterStruct.MapTypes).find(key => FilterStruct.MapTypes[key] === mapTitle)
        switch (tempMap){
            case "GlobalEmissionsByCountry":
                API.get('global/world-choropleth/?year='+year+'&region_data='+mapSubCategory, res => setData(res))
                break;
            case "GeographicGroups":
                API.get('global/eco-choropleth/?year='+year+'&region_data='+mapSubCategory, res => setData(res))
                break;
            case "EUCountries":
                API.get('eu/regions-choropleth/?year='+year+'&region_data='+mapSubCategory, res => setData(res))
                break;
            case "GeneralEmissionsOfSpanishRegions":
                API.get('esp/regions-choropleth/?year='+year+'&region_data='+mapSubCategory, res => setData(res))
                break;
            case "SpanishAutonomousCommunitiesByRegion":
                API.get('esp/sectors-choropleth/?year='+year+'&sector='+sector, res => setData(res))
                break
            case "SpanishAutonomousCommunitiesBySector":
                API.get('esp/sectors-breakdown-choropleth/?year='+year+'&region_data='+mapSubCategory+'&regions='+region, res => setData(res))
                break;
        }
        setMap(tempMap)
    };

    const setButtons = () => {
        if(map == "GlobalEmissionsByCountry" || map == "GeographicGroups") return MapButtonTypes.slice(0,2)
        else if(map == "SpanishAutonomousCommunitiesBySector" || "SpanishAutonomousCommunitiesByRegion") return []
        else return MapButtonTypes
    }

    const handleClick = (event) => {
        // console.log(data)
        // setCountryData(data)
    }

    return (
        <div>
            <Typography variant="h1" mb={1}> {gettext(currentMapTitle)} </Typography>

            <MapsHeader 
                mapTypes={FilterStruct.MapTypes} 
                changeTitle={changeTitle} 
                year={[year, setYear]}
                sector={[sector, setSector]} 
                region={[region, setRegion]} 
                onSubmit={onSubmit} />

            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                <Choropleth 
                    loading={data===null}
                    data={data}
                    map={map} 
                    handleClick={handleClick}/>

                {countryData !== null ? 
                    <Card sx={{ minWidth: 275, marginBottom: 2 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                            {countryData["name"]} ({countryData["code"]})
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            {gettext("Coords")}: {countryData["coords"]}, {countryData["coords"]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            {gettext("Data")}: {countryData["data"]+"Mt CO2"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            {gettext("Secondary")}: {countryData["secondary"]+"%"}
                            </Typography>
                        </CardContent>
                        </Card>
                
                :<></>}
            </Box>

            <ToggleButtonGroupFilter 
                values={setButtons()} 
                selected={mapSubCategory} 
                onCategoryChange={setMapSubCategory}/>

        </div>
    )
}
 
export default MapsPage;
 