import React, { useState, useEffect } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, Button, KeyboardArrowRightIcon, KeyboardArrowDownIcon, Typography } from 'src/shared';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { _ } from 'src/utils'
import { useTranslation } from 'src/contexts/TranslationContext';

export default function SideNavMenuItem(props) {
    const [showDropdown, set_showDropdown] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { gettext } = useTranslation() 

    useEffect(() => {
        set_showDropdown(false);
    }, [props.open])
    
    const selected = props.links.some(link => {
        if(link === location.pathname) {
            return true;
        }
        return false;
    });

    function selectedSubMenu(link) {
        if(link === location.pathname) {
            return link;
        }
    }

    function handleMenuClick() {
        if (props.isDropdown) {
            set_showDropdown(!showDropdown);
        }
        else {
            navigate(props.links[0]);
        }
    }

    return (
        (<div>
            <ListItem
                disablePadding 
                sx={{height: '56px'}}>
                {!props.open 
                    ? 
                    <IconButton
                        sx={{
                            marginLeft: '25px', 
                            backgroundColor: selected ? '#D3F6DF' : 'inherit'}}
                        size="large">
                        {props.icon}
                    </IconButton>
                    : 
                    <ListItemButton
                        sx={{
                            display: 'flex', 
                            marginLeft: '16px', 
                            marginRight: '16px', 
                            borderRadius: '100px',
                            height: '90%'}} 
                        selected={selected}
                        onClick={handleMenuClick}>

                            <ListItemIcon sx={{minWidth: '35px'}}> 
                                {props.icon}
                            </ListItemIcon>

                            <ListItemText 
                                disableTypography 
                                primary={
                                    <Typography variant="body1" style={{ fontWeight: selected ? '600' : '400' }}> {gettext(props.title)} </Typography>}/>
                            
                            { props.isDropdown && (showDropdown || selected)  ? <KeyboardArrowDownIcon /> 
                            : props.isDropdown && !showDropdown ? <KeyboardArrowRightIcon />
                                                                : null
                            }

                    </ListItemButton>
                }
            </ListItem>
            {props.open && props.isDropdown && (showDropdown || selected)
                ?
                <div className="sidenav-dropdown">
                    {props.links?.map((link, i) => 
                        <Link key={i} className="sidenav-dropdown-link" to={link}>
                            <Button 
                                className="sidenav-dropdown-item"
                                sx={{
                                    height: '30px',
                                    backgroundColor: selectedSubMenu(link) ? '#D3F6DF' : 'inherit',
                                    marginBottom: '5px',
                                    marginLeft: '80px',
                                    width: '72%', 
                                    justifyContent: 'initial', 
                                    borderRadius: '100px', 
                                    color: '#000000'}}>
                                <Typography variant="body2">- {gettext(props.subTitles[i])} </Typography>
                            </Button>
                        </Link>
                    )}
                </div>
                :
                null
            }
        </div>)
    );
}
