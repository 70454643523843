import React from 'react';
import "leaflet/dist/leaflet.css";
import '@toast-ui/chart/dist/toastui-chart.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { WarningBox } from 'src/shared'
import L, { LatLngBounds } from 'leaflet';
import { _ } from 'src/utils';
import { GenerateSectorMarkers, GenerateWorldMarkers, GenerateEUMarkers, GenerateESPMarkers } from './choropleth-markers';
import MapBlurred from "src/static/img/graphs/blurred/map.jpg";
import { useTranslation } from 'src/contexts/TranslationContext';

export function Choropleth(props){
    return <ChoroplethLoader {...props} />
}

function ChoroplethLoader(props){
    const { gettext } = useTranslation()

    let coords
    let zoom
    let marker
    let maxBounds
    let southWest
    let northEast
    if (props.data !== null && props.data.status !== 200 && !props.override){
        return(
            <div style={{width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>
                    <WarningBox error={true} marginLeft={'20px'} text={gettext("No valid data: Unfortunately there is no data for the parameters you have selected, please try a different combination")}/>
                </div>
                <div>
                    <img style={{minWidth: '100%'}} src={MapBlurred}/>
                </div>
            </div>
        )
    }
    switch (props.map){
      case "GeographicGroups":
        case "GlobalEmissionsByCountry":
            coords = [25,-25]
            zoom = 3
            marker = <GenerateWorldMarkers data={props.data} eventHandler={props.handleClick}/>
            southWest = L.latLng(-60, -170);
            northEast = L.latLng(80, 190);
            maxBounds = new LatLngBounds(southWest, northEast);
            break;
        case "EUCountries":
            coords = [55,10]
            zoom = 4
            marker = <GenerateEUMarkers data={props.data} eventHandler={props.handleClick}/>
            southWest = L.latLng(30, -25);
            northEast = L.latLng(70, 45);
            maxBounds = new LatLngBounds(southWest, northEast);
            break;
        
        case "SpanishAutonomousCommunitiesBySector":
            coords = [40,-5]

            marker = <GenerateSectorMarkers data={props.data} />
            zoom = 6
            southWest = L.latLng(33, -11);
            northEast = L.latLng(45, 5);
            maxBounds = new LatLngBounds(southWest, northEast);
            break;
        case "GeneralEmissionsOfSpanishRegions":
        case "SpanishAutonomousCommunitiesByRegion":
            coords = [40,-5]
            zoom = 6
            marker = <GenerateESPMarkers data={props.data} eventHandler={props.handleClick}/>
            southWest = L.latLng(33, -11);
            northEast = L.latLng(45, 5);
            maxBounds = new LatLngBounds(southWest, northEast);
            break;
    }

    if(props.loading === true){
        return <ChoroplethComponent coords={coords} zoom={zoom} marker={null} maxBounds={maxBounds} {...props} />
    }else{
        return <ChoroplethComponent coords={coords} zoom={zoom} marker={marker} maxBounds={maxBounds} {...props} />
    }
    
}

function ResetMap(props){
    const map = useMap()
    map.setMinZoom(props.zoom)
    map.setView(props.coords, props.zoom)
    return <></>
}

function ChoroplethComponent(props) {
    const { gettext } = useTranslation()
    let metadata = {
        type: '',
        units: '',
        type2: '',
        units2: ''
    }
    if(props.data !== null){
        if(props.data.data.metadata != undefined){
            metadata = props.data.data.metadata
        }else{
            metadata = props.data.metadata
        } 
    }

    const MetadataLoader = () => {
        if(metadata.type === ''){
            return <></>
        }
        return (
            <div id="chorolegend" className="chorolegend">
                <div style={{fontWeight: 'bold', height: '25px'}}>{gettext('Key:')}</div>
                {gettext('Name')}<br/>
                {metadata.type+ " " +metadata.units} <br/>
                {metadata.type2+ " " +metadata.units2}
            </div>
        )
    }

    

    return (
        <div className = "choroParent">
        <div id ="choro" className = "choro">
            <MapContainer style={{height: "100%", width: "100%"}}
                        center={props.coords}
                        zoom={props.zoom}
                        minZoom={props.zoom}
                        maxBounds={props.maxBounds}
                        scrollWheelZoom={false}
                        >

            <ResetMap coords={props.coords} zoom={props.zoom}/>

            <TileLayer
                attribution='Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            />
            <TileLayer
                attribution='Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            />

            { props.marker !== null ? props.marker : null }

            </MapContainer>
        </div>
            <MetadataLoader />
        </div>
    )
}

