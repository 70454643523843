import React, { useState, useEffect, useCallback } from 'react';
import { Loader } from '../shared';
import { _, i18n, Storage, AuthenticationService } from 'src/utils';
import { Navigation, Header } from 'src/views';
import { LoadingPageOrRoutes, Box, Backdrop } from 'src/shared';
import './top-level.css';
import { useMediaQuery } from '@mui/material';
import { HomePage } from 'src/views';
import { useTranslation } from 'src/contexts/TranslationContext';

const closedDrawerWidth = 88;
const { REACT_APP_API_URL } = process.env;

const TopLevel = (props) => {
    // State management
    const [csrf, setCsrf] = useState("");
    const [auth, setAuth] = useState(false);
    const [userAccount, setUserAccount] = useState(null);
    const [sideNavOpen, setSideNavOpen] = useState(false);
    const [basketItems, setBasketItems] = useState(
        JSON.parse(Storage.get('basket_items')) ?? []
    );

    const { language, changeLanguage } = useTranslation()

    // Mobile detection
    const isMobile = useMediaQuery('(max-width:768px)');

    // Fetch CSRF token
    const getCSRF = async () => {
        const csrfToken = await AuthenticationService.getCSRFToken();
        setCsrf(csrfToken);
    };

    // Fetch session
    const getSession = useCallback(async () => {
        try {
            const response = await fetch(`${REACT_APP_API_URL}/auth/session/`, {
                credentials: "include",
            });
            const data = await response.json();
            if (data.isAuthenticated) {
                setAuth(true);
                setUserAccount(data.profile);
            } else {
                setAuth(false);
                getCSRF();
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        getSession();
    }, [getSession]);

    // Handle authentication change
    const handleAuthChange = useCallback(
        async (userAccount) => {
            setAuth((prevAuth) => !prevAuth);
            setUserAccount(userAccount);

            if (userAccount) {
                changeLanguage(userAccount.profile.language);
            }
            await getSession();
        },
        [getSession, changeLanguage]
    );

    // Handle side nav toggle
    const handleSideNavChange = (open) => {
        setSideNavOpen(open);
    };

    // Add item to basket
    const handleAddToBasket = (chart) => {
        if (basketItems.findIndex((x) => x.id === chart.id) < 0) {
            const updatedItems = [...basketItems, chart];
            setBasketItems(updatedItems);
            Storage.set('basket_items', JSON.stringify(updatedItems));
        }
    };

    // Remove item from basket
    const handleRemoveFromBasket = (chart) => {
        const updatedItems = basketItems.filter((item) => item.id !== chart.id);
        setBasketItems(updatedItems);
        Storage.set('basket_items', JSON.stringify(updatedItems));
    };

    // Mobile view
    if (isMobile) {
        return <HomePage />;
    }

    // Loader for unauthenticated state
    if (csrf === "" && !auth) {
        return <Loader />;
    }

    return (
        <Box className="top-level-container">
            <Navigation
                sideNavOpen={sideNavOpen}
                handleSideNavChange={handleSideNavChange}
            />

            <Box
                component="main"
                className="main-container"
                sx={{
                    width: `calc(99vw - ${closedDrawerWidth}px) !important`,
                    marginLeft: `${closedDrawerWidth}px`,
                }}
            >
                <Header
                    csrf={csrf}
                    handleAuthChange={handleAuthChange}
                    auth={auth}
                    userAccount={userAccount}
                    basketItems={basketItems}
                    handleRemoveFromBasket={handleRemoveFromBasket}
                />

                <div className="view-container">
                    <LoadingPageOrRoutes
                        auth={auth}
                        csrf={csrf}
                        handleAddToBasket={handleAddToBasket}
                        loading={!language}
                        localised_data={props.state}
                    />
                </div>
            </Box>

            {sideNavOpen && (
                <Backdrop open={true} sx={{ position: 'absolute' }}></Backdrop>
            )}
        </Box>
    );
};

export default TopLevel;