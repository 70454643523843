import React from 'react';
import { _, localise_enum } from 'src/utils'
import FilterStruct, { Dropdown, YearsSelector } from 'src/shared'
import { useTranslation } from 'src/contexts/TranslationContext';

export function SummaryFilters (props) {

    const { gettext } = useTranslation()

    let yearlist;
    
    if (['RegSec', 'SecReg', 'DirReg', 'DirSec', 'Prov'].indexOf(props.page) != -1) {
        yearlist = FilterStruct.YearsESPDIR;
    } 
    else {
        yearlist = FilterStruct.Years;
    }

    const getYearFilters = () => {
        if(props.page != 'SecReg' && props.page != 'RegSec'){
            return  <YearsSelector 
                        label={gettext('Select years')}
                        key_value_map={localise_enum(yearlist)}
                        start_year={[props.startYear[0], props.startYear[1]]}
                        end_year={[props.endYear[0], props.endYear[1]]}
                        />
        }
        else{
            return <YearsSelector 
                        label={gettext('Select a year')}
                        key_value_map={localise_enum(yearlist)}
                        start_year={[props.startYear[0], props.startYear[1]]}
                        />
        }
    }

    const getRegionFilters = () => {
        if(props.page == 'Prov'){
            return <Dropdown
                        label={gettext('Select a region')}
                        key_value_map={localise_enum(FilterStruct.ESPRegions)}
                        value={props.region[0]}
                        on_change={props.region[1]}
                        />
        }else{
            return <></>
        }
    }

    return (
        <div>
            {getYearFilters()}
            {getRegionFilters()}
        </div>
    )
}
  