import React, { useState, useEffect } from 'react'
import {_, UserAccountService} from 'src/utils'
import { Button, Box, Link, Typography, IconButton, ArrowBackIcon, TextFieldInput, Loader, ApiError, InvalidFormState, ValidFormState } from 'src/shared';
import '../user-account.css';
import { useTranslation } from 'src/contexts/TranslationContext';

export default function ForgotPassword({csrf, handleCurrentViewChange, handleSuccess}) {
    const [loading, set_loading] = React.useState(false);
    const [apierror, set_apierror] = useState(false)
    const [requesterror, set_requesterror] = useState(null)
    const [requestsuccess, set_requestsuccess] = useState(null)
    const timer = React.useRef();
    const { gettext } = useTranslation()

    useEffect(() => {
        return () => {
          clearTimeout(timer.current);
        };
    }, []);

    const handleSubmit = async (event) => {
        if (!loading) {
            set_requestsuccess(null);
            set_loading(true);
            event.preventDefault();
            resetErrStates();
            const data = event.target;
            timer.current = window.setTimeout(async () => {
                const response = await UserAccountService.forgotPassword({
                    email: data.email.value,
                }, csrf);
                if (response.hasOwnProperty('error')) set_requesterror(response.error)
                else if (response == null && response == undefined ) set_apierror(true);
                else if (response !== null) 
                {
                    handleSuccess();
                    set_requestsuccess(response.detail);
                }
                else set_requesterror(gettext("Your request has failed. Try again"));
                set_loading(false);
            }, 1000);
        }
    }; 

    function resetErrStates(){
        set_apierror(false);
        set_requesterror(null);
    }

    return (
        <Box className="forgot-password-box box">
            <div className="title-container">
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <IconButton sx={{height: 30}} size="small" onClick={() => handleCurrentViewChange("Login")} disableFocusRipple={true}>
                        <ArrowBackIcon fontSize="inherit" color="primary"/>
                    </IconButton>
                    <Typography variant="h4"> {gettext("Forgot Password?")} </Typography>
                </div>
                <Typography variant="h5" mt={1} mb={1}> {gettext("Submit your email and we will send you a new one")} </Typography>
            </div>

            <Box component="form" onSubmit={handleSubmit}>
                <TextFieldInput id="email" label="Email" autoFocus={true} type="text"/>

                { apierror ? ApiError() : null }
                { requesterror !== null ? <InvalidFormState message={requesterror}/> : null }
                { requestsuccess !== null ? <ValidFormState message={requestsuccess}/> : null } 

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mt: 1, mb: 1 }}
                    className="submit-button button">
                        {gettext("Submit")}
                </Button>


                <span className="link-container">
                        <Typography variant="body2"> {gettext("Already received a code?")} &nbsp; </Typography>
                        <Link className="link" variant="body2" onClick={() => handleCurrentViewChange("Recover")} >
                            {gettext("Enter Code")}
                        </Link>
                    </span>
                

                {loading && (<Loader />)}

            </Box>
        </Box>
    )
}