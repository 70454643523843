import { API } from 'src/utils'

import Bar from "src/static/img/graphs/bar.png";
import Radar from "src/static/img/graphs/radar.png";
import HorizontalBar from "src/static/img/graphs/horizontal-bar.png";
import Line from "src/static/img/graphs/line.png";
import Pie from "src/static/img/graphs/pie.png";
import Vector from "src/static/img/graphs/vector.png";
import Table from "src/static/img/graphs/table.png"

import BarBlurred from "src/static/img/graphs/blurred/bar.jpg";
import RadarBlurred from "src/static/img/graphs/blurred/radar.jpg";
import HorizontalBarBlurred from "src/static/img/graphs/blurred/horizontal-bar.jpg";
import LineBlurred from "src/static/img/graphs/blurred/line.jpg";
import PieBlurred from "src/static/img/graphs/blurred/pie.jpg";
import VectorBlurred from "src/static/img/graphs/blurred/vector.jpg";
import TableBlurred from "src/static/img/graphs/blurred/table.jpg";

const chartSummaryIcons = {
    'line': Line,
    'single_line': Line,
    'bar' : HorizontalBar,
    'single_bar': HorizontalBar,
    'pie': Pie,
    'column': Bar,
    'radar': Radar,
    'vector': Vector,
    'table': Table
}

const failedAPIRequestPlaceholders = {
    'line': LineBlurred,
    'single_line': LineBlurred,
    'bar' : HorizontalBarBlurred,
    'single_bar': HorizontalBarBlurred,
    'pie': PieBlurred,
    'column': BarBlurred,
    'radar': RadarBlurred,
    'vector': VectorBlurred,
    'table': TableBlurred
}

export function ChartImage(chartType) {
    return chartSummaryIcons[chartType];
}

export function APIFailurePlaceholderImage(chartType) {
    return failedAPIRequestPlaceholders[chartType];
}

export async function ApiRequestBuilder(url, filters, filterValues, subcat, fn) {
    let apiURL = url + '?'
    filters.forEach(key => {
        if(filterValues[key] !== undefined) apiURL += key+'='+filterValues[key]+"&"
    });

    apiURL = apiURL.slice(0,-1)

    if(subcat !== null) {
        apiURL+="&region_data="+subcat
    }


    await API.get(apiURL, fn)   
}

export function FrozenPreviewChartFilters(selectedChartId, selectedChartType) {

    const DEFAULT_INITIAL_YEAR=2010
    const DEFAULT_START_YEAR=2020
    const DEFAULT_END_YEAR=2021
    const DEFAULT_YEAR=2022
    const DEFAULT_LEVEL=0

    let frozenFilter = {
        'start_year': DEFAULT_START_YEAR,
        'end_year': DEFAULT_END_YEAR,
        'level': DEFAULT_LEVEL
    }
    if(selectedChartType.indexOf('line') != -1 ){
        frozenFilter = {
            'start_year': DEFAULT_INITIAL_YEAR,
            'end_year': DEFAULT_END_YEAR,
            'level': DEFAULT_LEVEL
        }
    }

    if(selectedChartId.indexOf('variation') != -1 ){
        frozenFilter = {
            'start_year': DEFAULT_START_YEAR,
            'end_year': DEFAULT_END_YEAR,
            'level': DEFAULT_LEVEL
        }
    }

    if(selectedChartId == 'global/emission-breakdown' || selectedChartId == 'global/sector-breakdown'){
        frozenFilter = {
            'start_year': DEFAULT_START_YEAR,
            'end_year': DEFAULT_END_YEAR,
            'level': DEFAULT_LEVEL
        }
    }


    return frozenFilter;
}
