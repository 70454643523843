import React from 'react';
import { _ } from 'src/utils'
import { IconButton, ArrowCircleLeftOutlinedIcon, Typography } from 'src/shared';
import { SummaryFilters } from '../filters';
import { useTranslation } from 'src/contexts/TranslationContext';

export function SummaryHeader (props) {

    const {gettext} = useTranslation()

    return (
        <div className="summary-header">

            <div style={{display: 'flex', flexDirection: 'row', marginBottom: '10px'}}>
                <IconButton onClick={props.onBack} disableFocusRipple={true} size='medium'>
                    <ArrowCircleLeftOutlinedIcon color="primary" sx={{fontSize: 40, fontWeight: 200}} />
                </IconButton>
                <Typography variant="h1"> 
                    {gettext(props.title)}
                </Typography> 
            </div>

            <SummaryFilters {...props} />

        </div>
    )
}
  