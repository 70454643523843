import React, { useEffect, useState } from 'react'
import useDimensions from 'react-cool-dimensions'
import { _, VisualisationService } from 'src/utils'
import {
    EUChoropleth,
    EUTradingChoropleth,
    EUTypo,
    EUC02Summary,
} from 'src/visualisations'
import { ToggleButtonGroupFilter, WarningBox } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

function EUGlanceGraph(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const { ref, width, height, entry, unobserve, observe } = useDimensions({
        onResize: ({ width, height, entry, unobserve, observe }) => {
            set_width(width)
            set_height(height)
        },
    });

    const { gettext } = useTranslation()

    useEffect(() => {
        getCurrentView();
    }, [props.page])

    const getCurrentView = () => {
        if (props.data === 400) {
            return <WarningBox 
                        error={true}
                        marginLeft={'20px'} 
                        text={gettext('Your request cannot be fulfilled at the moment. Please try again later')}/>
        }
        switch (props.page) {
            case 'co2':
                return <EUC02Summary data={props.data} width={w} height={w} />
            case 'map1':
            case 'map2':
                return <EUGlanceChoropleth page={props.page} data={props.data} width={w} height={h} />
            case 'typo':
                return <EUTypo data={props.data} width={w} height={h} />
        }
    }

    return (
        <div ref={ref} className='eu-glance-page-visualisation-container-normal'>
            {getCurrentView()}
        </div>
    )
}

export default EUGlanceGraph;



function EUGlanceChoropleth(props) {
    const { gettext } = useTranslation()

    var MapButtonTypes = [
        { value: "map1", viewValue: gettext("Default") },
        { value: "map2", viewValue: gettext("Directive") },
    ]
    
    const [selectedMap, setSelectedMap] = useState(MapButtonTypes[0].value);
    const [map1, set_map1] = useState(props.data)
    const [map2, set_map2] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            getCurrentView();
            await VisualisationService.getChartData('eu/glance', 'map2', set_map2)
        }
        fetchData()
    }, [])

    const getCurrentView = () => {
        switch (selectedMap) {
            case 'map1':
                return <EUTradingChoropleth map={"EUCountries"} data={map1} width={props.width} height={"100%"} override={true}/>;
            case 'map2':
                return <EUChoropleth map={"EUCountries"} data={map2} width={props.width} height={"100%"} override={true}/>;
        }
    }

    return (
        <div>
            <div className='choropleth-container'>
                {getCurrentView()}
            </div>
            
            <div className='map-filter-container'>
                <ToggleButtonGroupFilter 
                    values={MapButtonTypes} 
                    selected={selectedMap} 
                    onCategoryChange={setSelectedMap}/>
            </div>
        </div>
    )
}
