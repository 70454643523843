import React, {useState} from 'react';
import {API, _} from 'src/utils'
import { DataGrid } from '@mui/x-data-grid';
import { esES, enUS } from '@mui/material/locale';
import { CSVLink } from "react-csv";
import { Button, MultiChartSelector, UploadRoundedIcon, Typography } from 'src/shared';
import { SummaryHeader } from 'src/views/summaries/selected-summary/summary-header';
import { useTranslation } from 'src/contexts/TranslationContext';

function MultiTable(props) {
    const {gettext} = useTranslation()

    const charts = Object.keys(props.data);
    const [selected, set_selected] = useState(charts[charts.length - 1]);
    let on_select = (new_selection) => set_selected(new_selection);

    const rowData = props.data[selected];
    const columns = Object.keys(props.data[selected].data[0]).map((key) => {
        return {field: key, headerName: key, flex: 1}
    });

    let count = 0;
    for (let row of rowData.data) {
        row.id = count;
        count++;
    }

    return (
        <div className='multichart-container'>

            {Object.hasOwn(props, 'startYear') ? <SummaryHeader {...props} title={rowData.title}/> : <></>}

            <div style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between'}}>
                <div style={{minWidth: '50%', width: '90%'}}>
                    <MultiChartSelector 
                        charts={charts}
                        selected={charts.length-1}
                        on_select={on_select} />
                </div>
                
                <CSVLink
                    className="table-csv-export-link" 
                    data={rowData.data} 
                    filename={"table-download.csv"}
                    headers={columns.map(x => x.headerName)}>

                        <Button
                            variant="outlined"
                            sx={{
                                width: '170px',
                                height: '35px',
                                borderRadius: '111px',
                                marginLeft: '15px'
                            }}>
                                <UploadRoundedIcon fontSize="small" sx={{marginRight: '8px'}} />
                                <Typography variant="h6">
                                    {gettext("Export as CSV")}
                                </Typography>
                        </Button> 

                </CSVLink>
            </div>
            
            <div style={{height: '58vh', width: '100%'}}>
                <DataGrid
                    localeText={API.language === 'es' ? esES.localeText : enUS.localeText}
                    columnVisibilityModel={{id: false}}
                    rows={rowData.data}
                    columns={columns}
                    title ={"table"}
                    bodyHeight={"auto"}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': { 
                            fontWeight: 'bold !important',
                            whiteSpace: "normal",
                            lineHeight: "normal"
                        }
                      }}
                />
            </div>

        </div>
    )
}

export default MultiTable
