import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { _ } from 'src/utils';
import { useTranslation } from 'src/contexts/TranslationContext';

function Dropdown(props) {
    let label = props.label ? props.label : '';
    const { gettext } = useTranslation()


    const handleChange = (event) => {
        props.on_change(event.target.value);
    };

    const drop_down_options = (key_value_map, display_values) => {
    
        return Object.keys(key_value_map).map(key => {
            let value = key_value_map[key];
            if (display_values) {
                return <MenuItem key={value} value={key} sx={{ maxHeight: '355px',
                    overflowY: 'auto',
                    marginBottom: '8px', }}> {gettext(value)} </MenuItem>;
            }
            return <MenuItem key={key} value={value} sx={{ maxHeight: '355px',
                overflowY: 'auto',
                marginBottom: '8px', }}> {gettext(key)} </MenuItem>;
        });
    }

    return (
        <StyledFormControl required size="small" textColorOverride={props.color || null} borderColorOverride={props.color || 'primary.main'}>
            <InputLabel
                id="select-input-label"
                sx={{
                    fontSize: 12,
                    marginTop: '2px'
                }}>
                {label}
            </InputLabel>

            <Select
                label={label}
                value={props.value}
                onChange={handleChange}
                MenuProps={{
                    PaperProps: {
                    sx: {
                        borderRadius: "8px !important",
                        width: "min-content",
                        width: '300px',
                        maxHeight: '400px',
                        fontSize: 12,
                        backgroundColor: "#F2F8F4",
                    },
                },
                MenuListProps: {
                    sx: {
                        "& li": {
                            fontWeight: 200,
                            paddingTop: 1,
                            paddingBottom: 1,
                            fontSize: "12px"
                        }
                    }
                },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                }}
                disabled={props.disabled}
                autoWidth
            >
                    {drop_down_options(props.key_value_map, props.display_values)}

            </Select>
        </StyledFormControl>
    );
}


const StyledFormControl = styled(FormControl, {
    shouldForwardProp: (prop) =>
      prop !== 'textColorOverride' && prop !== 'borderColorOverride',
  })(({ theme, textColorOverride, borderColorOverride }) => ({
    minWidth: '155px',
    marginLeft: '10px',
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
    "& .MuiInputLabel-root": {
      color: textColorOverride,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: '2px solid',
      borderColor: borderColorOverride,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: '2px solid',
      borderColor: borderColorOverride,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: '2px solid',
      borderColor: borderColorOverride,
    },
  }));

export { Dropdown };