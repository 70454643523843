import React from 'react'
import { _, localise_enum } from 'src/utils'
import FilterStruct, { Button, YearsSelector, Dropdown, ToggleableDropdown, WarningBox, customTheme } from 'src/shared'
import { useTranslation } from 'src/contexts/TranslationContext'

export function Filter(props){

    if (props.selectedChart == null){
        return <FilterBar {...props} />
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px'}}>

            <FilterBar {...props} />

            {!props.selectedChart.purchased && props.selectedChart.price != 0 
                ? <NotPurchasedBar {...props} /> 
                : <></> 
            }

        </div>
    )
}

function NotPurchasedBar(props){

    const {gettext} = useTranslation()

    const handleBasketClick = () => {
        props.onAddToBasket(props.selectedChart)
    }

    return (
        <div style={{display: 'inherit', alignItems: 'center'}}>

            <WarningBox 
                width={'500px'}
                marginLeft={'20px'} 
                text={gettext("You're currently viewing a preview of this chart. To gain full access to all parameters please add to basket and make a purchase")}/>

            <Button
                type="submit"
                variant="contained"
                color="primary"
                className="submit-button button"
                onClick={handleBasketClick}
                sx={{margin: '3px', marginLeft: '15px'}}>
                    {gettext('Purchase')}
            </Button> 

        </div>
    )
}

function FilterBar(props) {

    const {gettext} = useTranslation()


    const disabledFilter = (filterName) => {
        if (props.category == 'global' && filterName === "regions" && props.filters['level'][0] !== '2' ) {
            return true;
        }
        return (props.selectedChart !== null && !(props.selectedChart.purchased || props.selectedChart.price == 0)) || !displayFilter(filterName);
    };

    if(props.category == 'global'){
        return (
            <div key={props.category}>
                {/* Years */}
                <YearsSelector 
                    label={gettext('Select year range')}
                    key_value_map={localise_enum(FilterStruct.Years)}
                    start_year={props.filters['start_year']}
                    end_year={props.filters['end_year']}
                    disabled={disabledFilter("start_year")}
                    />
                {/* Grouping */}
                <Dropdown
                    label={gettext('Select grouping')}
                    key_value_map={localise_enum(FilterStruct.Level)}
                    value={props.filters['level'][0]}
                    on_change={props.filters['level'][1]}
                    color={customTheme.palette.filters.grouping}
                    disabled={disabledFilter("level")}
                    />
                {/* Regions */}
                <ToggleableDropdown
                    label={gettext('Select countries')}
                    names={Object.values(FilterStruct.GLRegions)}
                    labels={Object.keys(localise_enum(FilterStruct.GLRegions))}
                    selected={props.filters['regions'][0] ? props.filters['regions'][0] : []}
                    on_change={props.filters['regions'][1]}
                    color={customTheme.palette.filters.regions}
                    disabled={disabledFilter("regions")}
                    maxSelections={15}
                    /> 
            </div>
        )
    } else if(props.category == 'eu'){
        return (
            <div key={props.category}>
                {/* Years */}
                <YearsSelector 
                    label={gettext('Select year range')}
                    key_value_map={localise_enum(FilterStruct.Years)}
                    start_year={props.filters['start_year']}
                    end_year={props.filters['end_year']}
                    disabled={disabledFilter("start_year")}
                    /> 
                {/* Regions */}
                <ToggleableDropdown
                    label={gettext('Select regions')}
                    names={Object.values(FilterStruct.EURegions)}
                    labels={Object.keys(localise_enum(FilterStruct.EURegions))}
                    selected={props.filters['regions'][0] ? props.filters['regions'][0] : []}
                    on_change={props.filters['regions'][1]}
                    color={customTheme.palette.filters.regions}
                    disabled={disabledFilter("regions")}
                    /> 
                {/* Sectors */}
                <ToggleableDropdown
                    label={gettext('Select sectors')}
                    names={Object.values(FilterStruct.EUSectors)}
                    labels={Object.keys(localise_enum(FilterStruct.EUSectors))}
                    selected={props.filters['sectors'][0] ? props.filters['sectors'][0] : []}
                    on_change={props.filters['sectors'][1]}
                    color={customTheme.palette.filters.sectors}
                    disabled={disabledFilter("sectors")}
                    /> 
            </div>
        )
    } else if(props.category == 'esp'){
        return (
            <div key={props.category}>
                {/* Years */}
                <YearsSelector 
                    label={gettext('Select year range')}
                    key_value_map={localise_enum(FilterStruct.YearsESP)}
                    start_year={props.filters['start_year']}
                    end_year={props.filters['end_year']}
                    disabled={disabledFilter("start_year")}
                    />
                {/* Regions */}
                {
                    props.selectedChart != null && props.selectedChart.id == 'esp/province/summary' 
                    
                    ?
                    
                    <ToggleableDropdown
                        label={gettext('Select regions')}
                        names={Object.values(FilterStruct.ESPRegions)}
                        labels={Object.keys(localise_enum(FilterStruct.ESPRegions))}
                        selected={props.filters['regions'][0] ? [props.filters['regions'][0][0]] : []}
                        on_change={props.filters['regions'][1]}
                        color={customTheme.palette.filters.regions}
                        disabled={disabledFilter("regions")}
                        maxSelections={1}
                    /> 

                    :

                    <ToggleableDropdown
                        label={gettext('Select regions')}
                        names={Object.values(FilterStruct.ESPRegions)}
                        labels={Object.keys(localise_enum(FilterStruct.ESPRegions))}
                        selected={props.filters['regions'][0] ? props.filters['regions'][0] : []}
                        on_change={props.filters['regions'][1]}
                        color={customTheme.palette.filters.regions}
                        disabled={disabledFilter("regions")}
                    /> 

                }
                {/* Sectors */}
                <ToggleableDropdown
                    label={gettext('Select sectors')}
                    names={Object.values(FilterStruct.ESPSectors)}
                    labels={Object.keys(localise_enum(FilterStruct.ESPSectors))}
                    selected={props.filters['sectors'][0] ? props.filters['sectors'][0] : []}
                    on_change={props.filters['sectors'][1]}
                    color={customTheme.palette.filters.sectors}
                    disabled={disabledFilter("sectors")}
                    />
            </div>
        )
    }
    
    function displayFilter(filterId) {
        if(!props.selectedChart || (props.selectedChart && props.selectedChart.filters.includes(filterId))) {
            return true;
        }
        return false;
    }
}

export default Filter