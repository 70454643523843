import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom';
import {API, i18n, Storage} from './utils';
import { TopLevel } from './views';
import { ThemeProvider, StyledEngineProvider, customTheme } from 'src/shared';
import './static/css/style.css';
import { TranslationProvider } from './contexts/TranslationContext';

/** App class. */
class App extends React.Component {

    /** App constructor, intialise storage and language. */
    constructor(props) {
        super(props);
        this.state = {
            testGet: 'KO',
            auth: null,
        }
        this.handleAuthChange = this.handleAuthChange.bind(this)
    } 

    handleAuthChange() {
        this.setState({
            auth: 1
        })
    }

    /** @returns {JSX} The JSX for the main App. */
    render() {
        return (
            (<div>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={customTheme}>
                        <TranslationProvider>
                            <Router>
                                <TopLevel 
                                    state={this.state} 
                                />
                            </Router>
                        </TranslationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>)
        );
    }
};


export default App;
