import * as React from 'react';
import { _, ChartImage } from 'src/utils'
import { Card, CardHeader, CardContent, Typography, CardMedia, Button, customTheme } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

export function ChartCard(props) {

    const { gettext } = useTranslation()
    
    const handleViewClick = () => {
        props.onView(props.chart)
    }

    const handleBasketClick = () => {
        props.onAddToBasket(props.chart)
    }

    const handlePreviewClick = () => {
        props.onPreview(props.chart)
    }

    const FiltersNotDisplayed = ["start_year", "end_year"]

    const FilterColors = [
        //{id: 'end_year', color: "#C00000", description: "Years"},
        //{id: 'start_year', color: "#FF0000", description: "Year"},
        {id: 'sectors', color: customTheme.palette.filters.sectors, description: "Sectors"},
        {id: 'regions', color: customTheme.palette.filters.regions, description: "Regions"},
        {id: 'countries', color: customTheme.palette.filters.regions, description: "Countries"},
        {id: 'level', color: customTheme.palette.filters.grouping, description: "Grouping"},
    ]

  return (
    <Card 
        sx={{ 
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
            borderRadius: '11px',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
        
        <CardHeader 
            title={props.chart.title.replace('km2', 'km₂')} 
            titleTypographyProps={{variant: 'h5', color: 'primary'}}
            sx={{height: '45px',display: 'inline', verticalAlign: 'top'}}
            />

        <CardMedia
            component="img"
            height="90"
            image={ChartImage(props.chart.type)}
            sx={{background: customTheme.palette.chartSummary.main, borderRadius: '11px', width: '90%', marginLeft: '5%', marginRight: '5%', objectFit: 'contain'}}
            />

        <CardContent sx={{'&:last-child': { pb: '12px' }}}>
            
            <div style={{width: '100%', minHeight: '90px'}}>
                <Typography variant="body2" style={{fontWeight: 500}} gutterBottom>
                    {gettext(props.chart.chartSummary.replace('km2', 'km₂'))}
                </Typography>
            </div>

            <div style={{width: '100%', borderRadius: '26px', background: customTheme.palette.chartSummary.main, height: '4px', marginTop: '12px', marginBottom: '12px'}}></div>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="body2" color="text.secondary" sx={{marginBottom: '8px'}}>
                    {gettext("Filters Applied")}
                </Typography>
                {props.chart.filters?.length > 0 && props.chart.filters?.some(r => !FiltersNotDisplayed.includes(r))
                    ?
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {props.chart.filters?.map((filter, i) => 
                            <div key={i}>
                                { FilterColors.find(x => x.id === filter) !== undefined 
                                ? <div 
                                    key={i} 
                                    style={{
                                        width: '95px', 
                                        height: '23px', 
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '4px', 
                                        border: '2px solid',
                                        marginRight: '10px',
                                        borderColor: FilterColors.find(x => x.id === filter).color,
                                        color: FilterColors.find(x => x.id === filter).color}}>
                                            <Typography variant="body2">
                                                {gettext((props.route=="global" && FilterColors.find(x => x.id === filter).description == "Regions") ? "Countries" : FilterColors.find(x => x.id === filter).description )}
                                            </Typography>
                                    </div>
                                : <></>}
                            </div>
                        )}
                    </div>
                    : 
                    <Typography variant="body2" sx={{height: '26px', fontStyle: 'italic'}}>
                        {gettext("No filters needed")}
                    </Typography>
                }
            </div>

            <div style={{height: '24px', marginTop: '12px'}}>
                {!props.chart.purchased && props.chart.price != 0
                    ?
                    <Typography variant="h4" style={{fontWeight: 500}} gutterBottom>
                        {gettext(`€${parseInt(props.chart.price)}`)}
                    </Typography>
                    : <></>
                }
            </div>

            <div style={{width: '100%', borderRadius: '26px', background: customTheme.palette.chartSummary.main, height: '4px', marginTop: '12px', marginBottom: '12px'}}></div>

            {props.chart.purchased || props.chart.price == 0
                ? 
                <Button 
                    form="dashboard-filters-form"
                    type="submit"
                    name="filter-type"
                    value={props.chartType}
                    size="small" 
                    color="primary"
                    className="submit-button button"
                    variant="contained"
                    sx={{width: '122px', float: 'right'}}
                    onClick={handleViewClick}>
                        {gettext("View")}
                </Button>
                :
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button 
                        name="purchase"
                        size="small" 
                        color="primary"
                        className="button"
                        variant="outlined"
                        sx={{width: '122px'}}
                        onClick={handleBasketClick}>
                            {gettext("Purchase")}
                    </Button>

                    <Button 
                        form="dashboard-filters-form"
                        type="submit"
                        name="filter-type"
                        value={props.chartType}
                        size="small" 
                        color="primary"
                        className="submit-button button"
                        sx={{minWidth: '125px'}}
                        variant="contained"
                        onClick={handlePreviewClick}>
                            {gettext("Preview")}
                    </Button>
                </div>
            }

        </CardContent>
    </Card>
  )
}
 
export default ChartCard;