import React from 'react'
import { _, ChartImage, CategoryTitle } from 'src/utils'
import { Card, CardMedia, Typography, Box, DeleteOutlinedIcon, IconButton, Divider } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

export default function BasketItem(props) {

    const { gettext } = useTranslation()

    return (
        <div>
        <Card sx={{ 
                display: 'flex', 
                marginTop: '16px', 
                marginBottom: '16px',
                boxShadow: 'none',
                backgroundColor: 'inherit', 
                height: '60px' }}>

            <CardMedia
                component="img"
                sx={{ width: 60 }}
                image={ChartImage(props.item.type)}
            />

            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                marginLeft: '10px', 
                justifyContent: 'center',
                width: '276px'}}>
                <Typography variant="h5">
                    {gettext(props.item.title)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {gettext(CategoryTitle(props.item.res))}
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minWidth: '40px', 
                marginLeft: 'auto'}}>
                <IconButton 
                    size="small" 
                    onClick={() => props.handleRemoveFromBasket(props.item)} 
                    disableFocusRipple={true}
                    sx={{marginLeft: 'auto'}}>
                    <DeleteOutlinedIcon  />
                </IconButton>
                <Typography variant="h5" sx={{marginRight: '8px'}}>
                    {gettext(`€${props.item.price}`)}
                </Typography>
            </Box>

        </Card>

        <Divider />

        </div>
    )
}