import React, { useState, useEffect } from 'react'
import { _, VisualisationService } from 'src/utils'
import '../glance.css'
import { TabSelector, HelpTooltip, GraphOrLoading, Typography } from 'src/shared'
import EspGlanceGraph from './esp-glance-graph'
import { useTranslation } from 'src/contexts/TranslationContext'

function EspGlancePage() {
    const [page, set_page] = useState('co2')
    const [map, set_map] = useState(null)
    const [co2, set_co2] = useState(null)
    const [dir, set_dir] = useState(null)
    const { gettext } = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            await VisualisationService.getChartData('esp/glance', 'map', set_map)
            await VisualisationService.getChartData('esp/glance', 'co2', set_co2)
            await VisualisationService.getChartData('esp/glance', 'dir', set_dir)
        }
        fetchData()
    }, [])

    const getData = () => {
        switch (page) {
            case 'map':
                return map;
            case 'co2':
                return co2; 
            case 'dir':
                return dir; 
            default:
                return null;
        }
    }

    const handleCurrentViewChange = view => {
        set_page(view);
    };

    return (
        <div>

            <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="h1" mb={1}> 
                {gettext("At a Glance - Spain")} 
                </Typography>
                <HelpTooltip />
            </div> 

            <TabSelector 
                titles={[gettext("CO2 Summary"), gettext("Choropleth"), gettext("Directive Emissions Summary")]} 
                links={["co2", "map", "dir"]} 
                handleChange={handleCurrentViewChange}/>

            <GraphOrLoading 
                loading={getData()===null} 
                graph={
                    <EspGlanceGraph 
                        data={getData()} 
                        page={page}/>}/>
        </div>
    );
}

export default EspGlancePage
