import React, { useState } from 'react'
import { UserAccount, Basket } from 'src/views';
import { LanguageSelector } from 'src/shared';

export default function Header(props) {
    const [expandedLang, setExpandedLang] = useState(false);
    const [expandedUser, setExpandedUser] = useState(false);
    const [expandedBask, setExpandedBask] = useState(false)

    const handleMouseEnter = (type) => {
        if (type == 0){
            setExpandedLang(true);
            setExpandedUser(false);
            setExpandedBask(false);
        }else if (type == 1){
            setExpandedLang(false);
            setExpandedUser(true);
            setExpandedBask(false);
        }else if (type == 2){
            setExpandedLang(false);
            setExpandedUser(false);
            setExpandedBask(true);
        }
    };

    const handleMouseLeave = () => {
        setExpandedLang(false);
        setExpandedUser(false);
        setExpandedBask(false);
    };

    return (
        <div style={{
                display: 'flex',
                justifyContent: 'right',
                marginBottom: '10px'}}>

            <div 
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    borderRadius: '41px',
                    backgroundColor: '#F2F8F4',
                    marginRight: '16px',
                    width: expandedLang || expandedUser || expandedBask ? '240px' : '152px',
                    minWidth: '152px',
                    height: '50px',
                    paddingLeft: '3px',
                    paddingRight: '3px'}}>

                <LanguageSelector 
                    expanded={expandedLang}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    />

                <UserAccount
                    expanded={expandedUser}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    csrf={props.csrf} 
                    handleAuthChange={props.handleAuthChange} 
                    auth={props.auth}
                    userAccount={props.userAccount}>
                </UserAccount>

                <Basket
                    expanded={expandedBask}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    csrf={props.csrf} 
                    auth={props.auth}
                    basketItems={props.basketItems} 
                    handleRemoveFromBasket={props.handleRemoveFromBasket}
                    userAccountPlan={props.userAccount?.plan}/>

            </div>
        </div>
    )
}