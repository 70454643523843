import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { _ } from 'src/utils';

// Mock import statements for your icon images
import WordingLogo from "src/static/img/logos/wording-logo.png";
import DropdownFilter from "src/static/img/site-features/dropdown-filter.JPG";
import SideNavigation from "src/static/img/site-features/side-navigation.JPG";
import TopNavigation from "src/static/img/site-features/top-navigation.JPG";
import IconLogoGif from "src/static/img/logos/icon-logo.gif";

// Background images
import WorldImage from '../static/img/backgrounds/world.jpg';
import ForestImage from '../static/img/backgrounds/forest.jpg';
import LeafImage from '../static/img/backgrounds/leaf.jpg';
import OceanImage from '../static/img/backgrounds/ocean.jpg';
import { useTranslation } from 'src/contexts/TranslationContext';

const FullPageSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundImage',
})(({ backgroundImage }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  textAlign: 'center',
  color: '#fff',
  scrollSnapAlign: 'start',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay
    zIndex: 1,
  },
}));

const CenteredContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile
  padding: '20px',
  maxWidth: '70%',
  margin: 'auto',
  zIndex: 2,
}));

const HomeLogoContainer = styled(Box)({
  width: '302px',
  maxHeight: '165px',
  background: '#FFFFFF',
  borderRadius: '100px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '15px',
  marginBottom: '20px',
  justifyContent: 'center',
  zIndex: 5,
  border: '2px solid #fff',
});

const Icon = styled('img',{
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }) => ({
  width: isMobile ? '15vh' :'15vw',
  height: 'auto',
  marginRight: '20px',
  borderRadius: '15px',
}));

const OverlayText = styled(Typography,{
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }) =>({
  fontSize: isMobile ? '2.5vh' :'2.5vw',
  fontWeight: 'bold',
  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
  lineHeight: '1.3',
  zIndex: 5,
}));

const PageContainer = styled(Box)({
  height: '100vh',
  overflowY: 'scroll',
  scrollSnapType: 'y mandatory',
  scrollBehavior: 'smooth',
});

const CenteredContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  zIndex: 2,
});

const HomePage = () => {
  const { gettext } = useTranslation()
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <PageContainer>
      {/* First Section */}
      <FullPageSection backgroundImage={WorldImage}>
        <CenteredContainer>
          <HomeLogoContainer>
            <div style={{ height: '60%', marginLeft: '4%' }}>
              <img style={{ objectFit: 'scale-down', height: '10vh' }} src={IconLogoGif} alt="Icon Logo GIF" />
            </div>
            <div>
              <img style={{ maxWidth: '100%' }} src={WordingLogo} alt="Wording Logo" />
            </div>
          </HomeLogoContainer>
          <OverlayText isMobile={isMobile}> {gettext("Sustainable business starts here.")}</OverlayText>
          {isMobile && <OverlayText isMobile={isMobile}> {gettext("Visit us on desktop to get the full experience.")} </OverlayText>}
        </CenteredContainer>
      </FullPageSection>

      {/* Second Section */}
      <FullPageSection backgroundImage={ForestImage}>
        <CenteredContentWrapper isMobile={isMobile}>
          <Icon isMobile={isMobile} src={DropdownFilter} alt="Icon 2" />
          <OverlayText isMobile={isMobile}>{gettext("Use GEA's powerful filters to navigate all graphs")}</OverlayText>
        </CenteredContentWrapper>
      </FullPageSection>

      {/* Third Section */}
      <FullPageSection backgroundImage={LeafImage}>
        <CenteredContentWrapper isMobile={isMobile}>
          <Icon isMobile={isMobile} src={SideNavigation} alt="Icon 3" />
          <OverlayText isMobile={isMobile}>{gettext("Quickly access all pages via the interactive side navigation")}</OverlayText>
        </CenteredContentWrapper>
      </FullPageSection>

      {/* Fourth Section */}
      <FullPageSection backgroundImage={OceanImage}>
        <CenteredContentWrapper isMobile={isMobile}>
          <Icon isMobile={isMobile} src={TopNavigation} alt="Icon 4" />
          <OverlayText isMobile={isMobile}>{gettext("Use the top navigation to customise your experience")}</OverlayText>
        </CenteredContentWrapper>
      </FullPageSection>
    </PageContainer>
  );
};

export default HomePage;
