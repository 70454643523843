import React from 'react'
import { _ } from 'src/utils'
import { Tooltip, HelpOutlineIcon } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

export function HelpTooltip() {

    const { gettext } = useTranslation()

    return (
        <Tooltip
            title={gettext('Export charts via the 3 dot menu on the right-hand side of each visualisation')}
            arrow
            placement="bottom">
            <span>
                <HelpOutlineIcon fontSize="medium"/>
            </span>
        </Tooltip> 
    )
}

export function DashboardTooltip() {
    const { gettext } = useTranslation()

    return (
        <Tooltip
            title={<div style={{ whiteSpace: 'pre-line' }}>{gettext(`AC - Autonomous Community \n Directive Emissions - emissions subject to the EU\'s Industrial Emissions Directive `)}</div>}
            arrow
            placement="bottom">
            <span>
                <HelpOutlineIcon fontSize="medium"/>
            </span>
        </Tooltip> 
    )

}

 