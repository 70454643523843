import React, { useEffect, useState } from 'react'
import { VisualisationDimensions, _ } from 'src/utils'
import {
    EspChoropleth,
    EspTypo,
    Espco2Evo,
    EspFacEmi,
    EspDirEvo,
} from 'src/visualisations'
import { WarningBox } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

function EspGlanceGraph(props) {
    const [w, set_width]  = useState(0);
    const [h, set_height] = useState(0);
    const ref = VisualisationDimensions(w, h, set_width, set_height);
    const {gettext} = useTranslation()

    useEffect(() => {
        getCurrentView();
    }, [props.page])

    const getCurrentView = () => {
        if (props.data === 400) {
            return <WarningBox 
                        error={true}
                        marginLeft={'20px'} 
                        text={gettext('Your request cannot be fulfilled at the moment. Please try again later')}/>
        }
        switch (props.page) {
            case 'map':
                return(
                    <div ref={ref} className='esp-glance-page-visualisation-container'>
                        <EspChoropleth override={true} data={props.data} width={w} height={h} map={"GeneralEmissionsOfSpanishRegions"}/>
                    </div>
                );
            case 'co2':
                return (
                    <div ref={ref} className='esp-glance-page-visualisation-container'>
                        <Espco2Evo data={props.data} width={w} height={h} />
                        <EspTypo data={props.data} width={w} height={h} />
                    </div>
                );
            case 'dir':
                return (
                    <div ref={ref} className='esp-glance-page-visualisation-container'>
                        <EspFacEmi data={props.data} width={w} height={h} />
                        <EspDirEvo data={props.data} width={w} height={h} />
                    </div>
                );
        }
    }

    return (
        <React.Fragment>
            {getCurrentView()}
        </React.Fragment>
    )
}

export default EspGlanceGraph;