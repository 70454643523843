import React from 'react';
import { _, UserAccountService } from 'src/utils';
import { Button, Box, Link, Typography } from 'src/shared';
import '../user-account.css';
import { useTranslation } from 'src/contexts/TranslationContext';

export default function SummaryPage(props) {

    const {gettext} = useTranslation()

    return (
        <Box className="summary-box box">
            <React.Fragment>
                <div className="title-container">
                    <Typography variant="h4" mb={1}> {props.userAccount.first_name} {props.userAccount.last_name} </Typography>
                    <Typography variant="h5"> 
                        {gettext("Hi ")} {props.userAccount.first_name}{gettext(", you're currently using GEA's ")}{props.userAccount.plan}{gettext(" plan.")}
                    </Typography>
                </div>

                <div className="summary-body">
                    <div className="form-break">
                        <Link target="_blank" href="https://empresaclima.org/en/member-categories/">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="submit-button button">
                                    {gettext("Upgrade plan")}
                            </Button>
                        </Link>

                        <Button
                            variant="outlined"
                            color='primary'
                            className="button"
                            size="small"
                            onClick={async () => { await UserAccountService.handleLogout(props.handleCurrentViewChange, props.handleAuthChange) }}>
                            {gettext("Log out")}
                        </Button>
                    </div>

                    <div className="form-break" style={{marginTop: '15px'}}>
                        <Link className="link" variant="body2" onClick={() => props.handleCurrentViewChange("ChangePassword")} >
                            {gettext("Change password")}
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        </Box>
    );
}