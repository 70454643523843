import React from 'react';
import { CategoryTitle, _ } from 'src/utils'
import { ChartCard } from './chart-card'
import { Box, Grid, WarningBox } from 'src/shared';
import { useTranslation } from 'src/contexts/TranslationContext';

export function ChartCards(props) {
    const {gettext} = useTranslation()
    return (
        <div>
            <Box sx={{ maxWidth: '100%', marginTop: '20px' }}>
                {props.data !== undefined && props.data.length > 0 
                    ?
                    <Grid
                        container
                        spacing={{ xs: 2, sm: 2, md: 3 }}
                        columns={{ xs: 4, sm: 6, md: 9, lg: 12, xl: 15 }}>
                        {props.data.map((element, idx) => {
                            return (
                                <Grid
                                    item xs={3}
                                    sx={{minWidth: '248px'}}
                                    key={idx}>
                                    <ChartCard chart={element} owned={false} {...props} />
                                </Grid>)
                        })}
                    </Grid>
                    : 
                    <WarningBox 
                        error={true}
                        marginLeft={'20px'} 
                        text={gettext('No charts found for ')+gettext(CategoryTitle(props.route))+gettext('. Please contact Fundación Empresa y Clima')}/>
                }
            </Box>
        </div>

    )
}