import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  initializeI18n,
  detectUserLanguage,
  getLanguageCodeFromCountryCode,
  getCountryCodeFromLanguageCode,
} from './i18n';
import {API, Storage} from "src/utils"


const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(detectUserLanguage());
  const [gettext, setGettext] = useState(() => initializeI18n(language));

  // Update translations when the language changes
  useEffect(() => {
    setGettext(() => initializeI18n(language));
    API.set_language(language)
    Storage.set('language_code', language);
    document.getElementsByTagName('html')[0]
        .setAttribute('lang', language);
  }, [language]);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <TranslationContext.Provider
      value={{
        language,
        gettext,
        changeLanguage,
        getLanguageCodeFromCountryCode,
        getCountryCodeFromLanguageCode,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);