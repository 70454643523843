import React, { useState, useCallback } from 'react';
import _ from 'src/utils/i18n';
import { FormControl, Select, InputLabel, Checkbox, Button, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'src/contexts/TranslationContext';

const ToggleableDropdown = (props) => {
    const label = props.label || null;
    const names = props.names || null;
    const labels = props.labels || props.names;
    const maxSelections = props.maxSelections || names.length;


    // State
    const [selectOpen, setSelectOpen] = useState(false);
    const [valuesSelected, setValuesSelected] = useState(
        names.includes(props.selected[0]) ? props.selected : []
    );
    const [showAlert, setShowAlert] = useState(false);
    const { gettext } = useTranslation()

    // Handlers
    const setNone = useCallback(() => {
        setValuesSelected([]);
    }, []);

    const handleSelectOpen = useCallback(() => {
        setSelectOpen(true);
    }, []);

    const handleCheckboxChecked = useCallback(
        (name) => {
            const indexOfName = valuesSelected.indexOf(name);
            if (indexOfName > -1) {
                setValuesSelected((prev) => prev.filter((value) => value !== name));
                setShowAlert(false);
            } else {
                if (valuesSelected.length < maxSelections) {
                    setValuesSelected((prev) => [...prev, name]);
                } else {
                    setShowAlert(true);
                }
            }
        },
        [valuesSelected, maxSelections]
    );

    const handleSubmit = useCallback(() => {
        setSelectOpen(false);
        props.on_change(valuesSelected);
    }, [valuesSelected, props]);

    // Render checkboxes
    const renderCheckboxes = useCallback(
        (names, labels) =>
            names.map((name, index) => {
                const isChecked = valuesSelected.indexOf(name) > -1;
                const isDisabled = !isChecked && valuesSelected.length >= maxSelections;

                return (
                    <div
                        key={name}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        <Tooltip
                            title={isDisabled ? `You can select up to ${maxSelections} ${gettext('options')}` : ''}
                            arrow
                            placement="top"
                        >
                            <span>
                                <Checkbox
                                    size="small"
                                    checked={isChecked}
                                    onClick={() => handleCheckboxChecked(name)}
                                    disabled={isDisabled}
                                />
                            </span>
                        </Tooltip>
                        <Typography variant="body2">{labels[index]}</Typography>
                    </div>
                );
            }),
        [valuesSelected, maxSelections, handleCheckboxChecked]
    );

    return (
        <StyledFormControl
            required
            size="small"
            color={props.color}
            disabled={props.disabled}
        >
            <InputLabel
                id="select-input-label"
                sx={{
                    fontSize: 12,
                    marginTop: '2px',
                }}
                disabled={props.disabled}
            >
                {label}
            </InputLabel>

            <Select
                open={selectOpen}
                onOpen={handleSelectOpen}
                onClose={handleSubmit}
                multiple
                value={valuesSelected}
                label={label}
                disabled={props.disabled}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: '0px 24px 24px 24px',
                            width: '300px',
                            height: '400px',
                            backgroundColor: '#F2F8F4',
                            fontSize: 12,
                            paddingRight: '12px',
                            paddingBottom: '8px',
                        },
                    },
                    MenuListProps: {
                        sx: {
                            '& li': {
                                fontWeight: 200,
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontSize: '12px',
                            },
                        },
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                renderValue={(selected) => `${selected.filter(Boolean).length} ${gettext('selected')}`}
            >
                <div
                    style={{
                        maxHeight: '355px',
                        overflowY: 'auto',
                        marginBottom: '8px',
                    }}
                >
                    {renderCheckboxes(names, labels)}
                </div>

                <div style={{ height: 22, width: '100%' }}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        sx={{
                            float: 'left',
                            marginBottom: '5px',
                            marginLeft: '15px',
                            width: 70,
                            height: 25,
                            fontSize: 12,
                            borderRadius: 100,
                        }}
                        onClick={setNone}
                    >
                        {gettext('Reset')}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        sx={{
                            float: 'right',
                            marginBottom: '5px',
                            width: 70,
                            height: 25,
                            fontSize: 12,
                            borderRadius: 100,
                        }}
                        onClick={handleSubmit}
                    >
                        {gettext('Apply')}
                    </Button>
                </div>
            </Select>
        </StyledFormControl>
    );
};

const StyledFormControl = styled(FormControl)(({ theme, color, disabled }) => ({
    minWidth: '155px',
    marginLeft: '10px',
    '& .MuiInputLabel-asterisk': {
        display: 'none',
    },
    '& .MuiInputLabel-root': {
        color: disabled || color === null ? theme.palette.primary.main : color,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid',
        borderColor: color === null ? theme.palette.primary.main : color,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '2px solid',
        borderColor: disabled || color === null ? theme.palette.primary.main : color,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid',
        borderColor: color === null ? theme.palette.primary.main : color,
    },
}));

export default ToggleableDropdown;